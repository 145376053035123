.alertWidgetContainer {
    margin-bottom: 10px;
}

.alertWidgetContainer ul:first-child {
    list-style: none;
    display: inline-flex;
}

.alertWidgetContainer .header {
    color: #2f59c8;
    font-weight: bold;
    margin-bottom: 0;
}

.alertWidgetContainer .notificationSettings {
    color: #2f59c8;
    border: transparent 1px solid;
    font-weight: bold;
    text-align: right;
    font-size: 14px;
}

.alertWidgetContainer .notificationSettings:hover {
    color: #7897ed;
}

.alertWidgetContainer table {
    width: 100%;
    padding: 10px 10px 10px;
    box-shadow: 2px 2px 10px #acacac;
}

.alertWidgetContainer table td {
    border-bottom: 1px solid lightgray;
}

.alertWidgetContainer .table_outer_ul {
    list-style: none;
    padding: 10px 10px 0px 10px;
    display: flex;
}

.alertWidgetContainer .table_inner_ul {
    list-style: none;
    padding: 10px;
}

.alertWidgetContainer .bar {
    min-height: 50px;
    background: #2f59c8;
    width: 5px;
    height: 100%;
}

.alertWidgetContainer li {
    position: relative;
    font-size: 12px;
    font-weight: bold;
}

.alertWidgetContainer .time {
    color: gray;
    padding-right: 10px;
}

.alertWidgetContainer .buttonContainer {
    position: relative;
}

.alertWidgetContainer .buttonContainer button {
    position: absolute;
    right: 10px;
    top: -12px;
    border: 1px solid gray;
    color: white;
}

.alertWidgetContainer .note {
    font-size: 10px;
    font-style: italic;
    color: gray;
}